import React from 'react';
import PurchaseRocks from './PurchaseRocks';
import PossibleRocks from './PossibleRocks';
import RemainingRocks from './RemainingRocks';
import './App.css';
import { max_rocks } from './constants';


const App = () => {
  return (
    <div className="App">
      <p className="Text">
        March 26th, 2021 - The first ever 3d generated collection on ethereum is born.
        Digirocks are a part of NFT history, having been created a month before BAYC!
      </p>
      <div className='ImageBox'>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="Image" src='/gifs/gif-17.gif' alt='rock1'/>
          </a>
        </div>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="Image" src='/gifs/gif-101.gif' alt='rock2'/>
          </a>
        </div>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="Image" src='/gifs/gif-37.gif' alt='rock3'/>
          </a>
        </div>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="Image" src='/gifs/gif-1.gif' alt='rock4'/>
          </a>
        </div>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="Image" src='/gifs/gif-103.gif' alt='rock4'/>
          </a>
        </div>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="Image" src='/gifs/gif-65.gif' alt='rock4'/>
          </a>
        </div>
      </div>
      <h2 className="Subheader">
        Details
      </h2>
      <p className="Text">
        Every DigiRock is a unique algorithmically generated ERC721 token with its image and attributes
        {' '} stored in IPFS. There are a ton of different traits
        you can get for your rock, like the rare <strong>Magma</strong> or <strong>Obsidian</strong> body types and the 
        <strong> Rocket Launcher</strong> or <strong>Electric Guitar</strong> attributes!
      </p>
      <p className="Text">
        <strong>
          This is the official contract {' '}
          <a target="_blank" rel="noreferrer" href='https://etherscan.io/address/0xebaeb894205666314f06b8e5f61fc0ffe8039ce4'>0xebaeb894205666314f06b8e5f61fc0ffe8039ce4</a>
        </strong>
      </p>
      <RemainingRocks />
      <PurchaseRocks />
      <PossibleRocks />
      <h2 className="Subheader" style={{marginTop: '50px'}}>
        Roadmap
      </h2>
      <p className="Text">
        <h3>1 second before you make the greatest purchase of your life</h3> You don't own a DigiRock<br />
        <h3>1 second after you make the greatest purchase of your life</h3> You own a DigiRock
      </p>
      <h2 className="Subheader" style={{marginTop: '40px'}}>
        FAQ
      </h2>
      <p className="Text">
        <h3 className="Subheader">What is a DigiRock?</h3>
        It's an NFT! And you will have full ownership and commercial rights usage over the one you own
      </p>
      <h2 className="Subheader" style={{marginTop: '50px'}}>
        About
      </h2>
      <p className="Text">
        My name is Matt (also known as <strong>slipery</strong>), and I am a software engineer who's been involved with NFTs since 2018. 
        My partner in crime on this project is my brother, Mike (also known as... Mike), who worked on some of the art. <br /><br />

        In addition to DigiRocks, I am also the creator of <strong>CryptoFreaks</strong> (with Mike, see some below!) and 
        <strong> Hotcakes on WAX </strong>(now defunct)
      </p>
      <div className='ImageBox'>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/cryptofreaks'>
            <img className="Image" src='/cryptofreaks/freak1.png' alt='freak1'/>
          </a>
        </div>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/cryptofreaks'>
            <img className="Image" src='/cryptofreaks/freak2.png' alt='freak2'/>
          </a>
        </div>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/cryptofreaks'>
            <img className="Image" src='/cryptofreaks/freak3.png' alt='freak3'/>
          </a>
        </div>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/cryptofreaks'>
            <img className="Image" src='/cryptofreaks/freak4.png' alt='freak4'/>
          </a>
        </div>
        <div className="ImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/cryptofreaks'>
            <img className="Image" src='/cryptofreaks/freak5.png' alt='freak4'/>
          </a>
        </div>
      </div>
      <div className="BottomBar">
        <div className="BottomSide">
          <p className="Text">
            © 2024 mdigital Inc.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
